<script setup lang="ts">
import type { StrapiLocale } from '@nuxtjs/strapi/dist/runtime/types'
import type {
  strapiNavigationItem,
  strapiOptions,
} from '~/interfaces/strapi.interface'
import { useStore } from '~/store/main'

const { find, findOne } = useStrapi()
const { locale } = useI18n()
const store = useStore()
const config = useRuntimeConfig()
const route = useRoute()
const gtm = useGtm()

onMounted(() => {
  window.sessionStorage.setItem('locale', locale.value)

  if (config.public.cookiebot) {
    const script = document.createElement('script')
    script.setAttribute('data-cbid', config.public.cookiebot)
    script.setAttribute('id', 'Cookiebot')
    script.setAttribute('data-blockingmode', 'auto')
    script.type = 'text/javascript'
    script.src = 'https://consent.cookiebot.com/uc.js'
    document.head.appendChild(script)
  }
})

watch(
  () => route.fullPath,
  () => {
    open.value = false
    store.$patch({
      isMenuAccountOpen: false,
    })
  }
)

const topMenu = ref()
const headerMenu = ref()
const footerMenu = ref()

const topNavigation =
  locale.value === 'fr'
    ? 'navigation/render/top-navigation?type=TREE'
    : 'navigation/render/top-navigation-en?type=TREE'

const { data: dataTop } = await useAsyncData('topNavigation', () =>
  find<strapiNavigationItem[]>(topNavigation)
)

if (dataTop.value) topMenu.value = dataTop.value
else topMenu.value = []

let headerNavigation: string

const { status } = useAuth()
if (locale.value === 'fr') {
  if (status.value === 'authenticated') {
    headerNavigation = 'navigation/render/header-navigation-connected?type=TREE'
  } else {
    headerNavigation = 'navigation/render/header-navigation?type=TREE'
  }
} else if (status.value === 'authenticated') {
  headerNavigation =
    'navigation/render/header-navigation-connected-en?type=TREE'
} else {
  headerNavigation = 'navigation/render/header-navigation-en?type=TREE'
}

const { data: dataHeader } = await useAsyncData('headerNavigation', () =>
  find<strapiNavigationItem[]>(headerNavigation)
)

if (dataHeader.value) headerMenu.value = dataHeader.value
else headerMenu.value = []

const footerNavigation =
  locale.value === 'fr'
    ? 'navigation/render/footer-navigation?type=TREE'
    : 'navigation/render/footer-navigation-en?type=TREE'

const { data: dataFooter } = await useAsyncData('footerNavigation', () =>
  find<strapiNavigationItem[]>(footerNavigation)
)

if (dataFooter.value) footerMenu.value = dataFooter.value
else footerMenu.value = []

// options
const { data: options } = await useAsyncData('options', () =>
  findOne<strapiOptions>('option', {
    populate:
      'url_match, socials, socials.icon, logo_header, logo_footer, contact_image, logo_blue, image_register, login_background, image_register, consent_image',
    locale: locale.value as StrapiLocale,
  })
)

const closeLogin = () => {
  gtm.trackEvent({
    event: 'login',
    action: 'close',
    value: 'Click on the cross in login page',
  })
  window.location.href = '/'
}

provide('options', options)
</script>

<template>
  <div class="layout-login">
    <button
      v-if="route.name !== 'frPartnerOnboardingSelectPartner'"
      class="text-primary absolute right-24 top-24 z-40 block"
      @click="closeLogin"
    >
      <UIcon name="i-fa6-solid-xmark" class="h-24 w-24 lg:text-white" />
    </button>
    <slot />
    <AkFooter class="mt-0" :menu="[...footerMenu]" />
    <UNotifications />
    <NuxtLoadingIndicator color="#269999" />
    <AkConsent
      :title="options?.data.attributes.consent_title"
      :info="options?.data.attributes.consent_info"
      :image="options?.data.attributes.consent_image"
      :text="options?.data.attributes.consent_text"
    />
  </div>
</template>

<style lang="scss">
.layout-login {
  @media (min-width: 1280px) {
    min-height: 100vh;
    display: grid;
    grid-template-rows: 1fr auto;
  }
}
</style>
